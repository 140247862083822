export default {
  page: {
    title: {
      base: 'Passerelle de paiement Autopay',
      start: 'Choix du mode de paiement',
      visadata: 'Visa Mobile',
      continue: 'Continuer - Choisir un moyen de paiement',
      wait: 'Attente',
      confirmation: 'Confirmation de paiement',
      thankYou: 'Merci pour le paiement',
      error: 'Erreur de page',
      errorNotFound: 'Page non trouvée',
      secureBack: 'Page de chargement',
      maintenance: 'Site en maintenance',
      regulationsApproval: 'Règlements et politique de confidentialité',
      toTransferData: 'détails du virement',
      sessionTimeout: 'Page après la fin de la session',
      paywayError: 'Canal de paiement indisponible'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Augmentez la taille de la police de caractère',
      decreaseFontSize: 'Diminuez la taille de la police de caractère',
      changeContrast: 'Modifiez le contraste',
      changeLanguage: 'Modifier la langue du site',
      skipToContent: 'Aller au contenu',
      skipToContentLabel: 'Aller au contenu principal',
      logoTitle: 'Logo du site'
    },
    sessionTimer: {
      timeOut: 'La session a expiré',
      sessionEnd: 'La fin de la session sous :',
      label: 'Prolongez la durée de la session',
      button: 'Prolongez'
    }
  },
  footer: {
    text1: 'Avez-vous des questions supplémentaires liées aux paiements en ligne ou d’autres services ? <a title="Ouvrir dans une nouvelle fenêtre" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" target="_blank" rel="noreferrer">Aller à la page d\'aide</a>',
    text2: {
      part1: 'Le responsable des données à caractère personnel est Autopay S.A.',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. La fourniture des données à caractère personnel est volontaire, mais nécessaire pour l’exécution la déclaration envoyée. La base juridique, l’objectif, la durée de traitement des données à caractère personnel et les droits de l’Utilisateur, ainsi que d’autres informations importantes relatives au traitement des données à caractère personnel sont prévus de manière plus détaillée dans <a href="{privacyPolicyUrl}" target="_blank" title="Aller à la page avec le document Politique de confidentialité (128 KB, PDF)" rel="noreferrer">la Politique de confidentialité de Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Affichez les informations supplémentaires',
    linkShowMoreText: 'Lire la suite',
    linkShowLessTitle: 'Cachez les informations supplémentaires',
    linkShowLessText: 'Lire moins'
  },
  paywall: {
    header: 'Sélectionnez le mode de paiement',
    info: {
      paywayNotAvailable: 'Nous sommes désolés, mais il semble que le mode de paiement sélectionné est impossible en ce moment sur votre appareil.',
      applePayNotAvailable: 'Nous sommes désolés, mais il semble que le paiement à l’aide d’Apple Pay est impossible en ce moment sur votre appareil.',
      googlePayNotAvailable: 'Nous sommes désolés, mais il semble que le paiement à l’aide de Google Pay est impossible en ce moment sur votre appareil.'
    },
    paywayGroup: {
      inactiveMessage: 'Le paiement est actuellement indisponible',
      notice: {
        novelty: 'NOUVEAUTÉ',
        promotion: 'PROMOTION',
        recommended: 'CONSEILLÉ',
        mastercard: 'LE PAIEMENT LE PLUS SÛR SUR INTERNET',
        mastercardC2P: 'LE PAIEMENT LE PLUS SÛR SUR INTERNET',
        blik: 'BLIKOMANIA - Inscrivez-vous et gagnez des Diamants!',
        visaMobile: 'Payez de manière fluide et sûre'
      },
      bank: {
        many: 'banques',
        one: 'banque',
        two: 'banques'
      },
      wallet: {
        many: 'portefeuilles',
        one: 'portefeuille',
        two: 'portefeuilles'
      },
      otp: {
        many: 'façons',
        one: 'chemin',
        two: 'façons'
      },
      blikPayLater: {
        info: 'En savoir plus'
      }
    },
    paywayList: {
      label: 'Groupe de types de paiement',
      linkSelectOther: 'Sélectionnez un autre'
    },
    paywaySingle: {
      accountAtAnotherBank: 'J\'ai un compte dans une autre banque'
    },
    paywaySwitch: {
      methodShortSingle: 'Jetable',
      methodLongSingle: 'Paiements uniques',
      methodShortAuto: 'Cyclique',
      methodLongAuto: 'Paiements récurrents'
    },
    form: {
      payway: {
        label: 'Sélectionnez le mode de paiement'
      },
      card: {
        label: 'Saisissez les données de la carte',
        iframe: {
          title: 'Formulaire avec les données de la carte de crédit à remplir'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Formulaire avec numéro de téléphone à remplir'
        },
        status: {
          pending: {
            statusText: 'En attente de confirmation',
            header: 'Confirmez le paiement dans l\'application',
            descriptionText1: 'Vous confirmerez le paiement dans l\'application Visa Mobile ou l\'application bancaire avec le service Visa Mobile activé',
            descriptionText2: 'Après avoir confirmé la transaction dans l\'application, attendez le résultat du paiement.',
            descriptionText3: 'Annulation de la transaction en cours',
            cancelButton: 'Annulez le paiement'
          },
          success: {
            statusText: 'Merci pour votre achat!',
            descriptionText: 'Paiement effectué avec succès'
          },
          error: {
            statusText: 'Assurez-vous que vous disposez de fonds suffisants sur la carte de paiement Visa Mobile utilisée pour le paiement.',
            descriptionText: 'Paiement rejeté'
          }
        },
        apps: 'Applications de paiement Visa Mobile',
        help: 'Avez-vous besoin d\'aide?',
        info: 'Pour en savoir plus',
        secure: 'Paiement sécurisé grâce au cryptage SSL',
        modal2: {
          header: 'Pour confirmer le paiement, ouvrez l\'application Visa Mobile ou l\'application de la banque dans laquelle vous avez un service Visa Mobile activé',
          listText1: 'vous avez installé l\'application Visa Mobile ou le service Visa Mobile est actif dans l\'application mobile de votre banque',
          listText2: 'vous avez activé les notifications dans votre application Visa Mobile ou votre application bancaire avec le service Visa Mobile activé',
          listText3: 'le nombre que vous avez donné est correct: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Assurez-vous également que...'
        }
      },
      click2pay: {
        loaderText: 'Nous recherchons votre adresse e-mail<br>dans <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Enregistrez votre carte',
          text2: 'Vos données seront enregistrées en toute sécurité dans <strong>Mastercard Click to Pay</strong>',
          link: 'En savoir plus'
        },
        email: {
          labelForClick2Pay: 'Adresse e-mail pour les notifications de paiement'
        },
        cardSelect: {
          selected: 'Sélectionné',
          select: 'Sélectionner',
          payOther: 'Entrez les détails de la carte',
          back: 'Retour'
        },
        cardSelected: {
          header: 'Utiliser une carte enregistrée dans Click to Pay',
          date: 'valable jusqu\'à',
          buttonChange: 'Changer'
        },
        codeForm: {
          description: 'Nous avons trouvé votre adresse e-mail dans Click to Pay.',
          codeSentEmail: 'Saisissez le code à usage unique que nous avons envoyé à {email} pour vous connecter à votre compte.',
          codeSentPhone: 'Saisissez le code SMS unique que nous avons envoyé à {phone} pour vous connecter à votre compte.',
          codeLabel: 'Entrez le code',
          codePlaceholder: 'entrez le code',
          codeLabelFlow: 'Code',
          codeSendLabel: 'Envoyer un nouveau code',
          codeSendAriaLabel: 'via',
          codeSendAriaLabel2: 'ou',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'email',
          trustedLabel: 'Ajoutez cet appareil à votre liste de confiance<br><span>La prochaine fois, nous reconnaîtrons cet appareil et vous paierez sans vous connecter</span>',
          buttonSubmit: 'Suivant',
          buttonChangeCard: 'Entrez les détails de la carte'
        },
        info: {
          description: 'Le paiement par carte est crypté et sécurisé. La transaction sera autorisée via 3DSecure sur le site Internet de la banque.'
        },
        loginForm: {
          emailLabel: 'Entrez votre adresse email pour vous connecter à Click to Pay',
          emailPlaceholder: 'adresse e-mail',
          emailLabelFlow: 'E-mail',
          buttonSubmit: 'Connexion',
          buttonBack: 'Retour'
        },
        newUserForm: {
          header: 'Enregistrer la carte dans Click to Pay',
          description: 'Nous avons besoin de quelques détails supplémentaires pour enregistrer votre carte.',
          emailLabel: 'Entrez l\'adresse e-mail',
          emailPlaceholder: 'adresse e-mail',
          emailLabelFlow: 'E-mail',
          countryLabel: 'Pays',
          firstnameLabel: 'Nom',
          firstnamePlaceholder: 'nom',
          firstnameLabelFlow: 'Nom',
          lastnameLabel: 'Nom de famille',
          lastnamePlaceholder: 'nom',
          lastnameLabelFlow: 'nom',
          phoneLabel: 'Numéro de téléphone',
          phonePlaceholder: 'numéro de téléphone',
          phoneLabelFlow: 'Téléphone',
          countryCodePlaceholder: 'code',
          countryCodeLabelFlow: 'Code',
          trustedLabel: 'Ajoutez cet appareil à votre liste de confiance<br><span>La prochaine fois, nous reconnaîtrons cet appareil et vous paierez sans vous connecter</span>',
          termsLabel: 'En continuant, vous acceptez les <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Conditions d\'utilisation</a> et vous comprenez que vos données seront traitées conformément à la <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank ">Politique de confidentialité</a>Mastercard.',
          c2pInfo: 'Click to Pay utilisera ces informations pour vous vérifier et envoyer des codes de vérification à ce numéro. Des frais de message/données peuvent s\'appliquer.',
          buttonSubmit: 'Enregistrer et payer',
          buttonBack: 'Payez sans enregistrer votre carte'
        },
        profile: {
          header: 'Connectez-vous à Click to Pay',
          buttonChangeEmail: 'Modifier l\'adresse e-mail'
        },
        modal: {
          title: 'Cliquez pour payer - informations',
          header: 'Payez rapidement et en toute sécurité, sans réécrire les détails de votre carte dans le nouveau standard Click to Pay',
          text1: 'Lorsque vous payez par carte, recherchez le logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> et payez avec votre carte de paiement. rapidement et facilement.',
          text2: 'Click To Pay est une nouvelle norme de paiement par carte prise en charge par Visa et Mastercard.',
          text3: 'Vous pouvez payer avec la carte de votre compte Click to Pay dans les magasins en Pologne et à l\'étranger, sans copier les données de la carte.',
          text4: 'Les données sont enregistrées en toute sécurité dans Visa ou Mastercard. Toutes les informations sont entièrement cryptées.',
          text5: 'Lors des paiements ultérieurs, Click to Pay reconnaîtra votre adresse e-mail afin que vous puissiez payer immédiatement avec vos cartes enregistrées.',
          text6: 'Vous confirmez en outre les paiements auprès de votre banque, ce qui garantit une sécurité totale.',
          processError: 'Une erreur s\'est produite lors de l\'exécution du paiement Click to Pay. Effectuez un paiement par carte standard.'
        }
      },
      email: {
        label: 'Saisissez l’adresse courriel',
        flowLabel: 'Courriel',
        tip: 'Nous avons besoin de votre adresse courriel pour pouvoir vous envoyer des messages concernant le statut du paiement.'
      },
      terms: {
        header: 'Consentements et déclarations',
        selectAll: 'Cochez tous',
        fieldNotRequired: 'Champ facultatif',
        partnerMarketingTextLess: 'Voulez-vous recevoir de notre part des notifications par courriel, par SMS ou par téléphone sur les meilleures offres, promotions, concours et d’autres évènements organisés par nous ou par nos partenaires?',
        partnerMarketingTextMore: 'Nous vous promettons que nous n’allons pas vous envoyer le spam ! Si nous messages sont ennuyant pour vous, vous pouvez retirer votre consentement à tout moment.',
        tradeMarketingTextLess: 'Souhaitez-vous que nous puissions communiquer votre adresse courriel ou votre téléphone pour que nous puissions vous présenter des informations et des offres intéressantes ?',
        tradeMarketingTextMore: 'Nous assurons que le groupe de nos partenaires sont des entreprises reconnus qui n’aiment pas envoyer le spam tout comme nous-mêmes et que vous pouvez retirer votre consentent à tout moment.'
      },
      termsLabelDefault: 'J\'ai lu et j\'accepte <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Télécharger les conditions générales du service" rel="noreferrer">les Conditions générales du service de paiement</a> et <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Télécharger la politique de confidentialité de Autopay S.A.">la Politique de confidentialité</a>. Je souhaite que le service soit effectué immédiatement, et en cas de résiliation du contrat, je ne serais pas remboursé des frais engagés pour les services fournis à ma demande jusqu\'au moment de la résiliation du contrat.',
      termsReccuring: 'Si vous souhaitez effectuer un paiement, acceptez <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Télécharger les conditions générales du service" rel="noreferrer">les Conditions générales</a> et <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Télécharger la politique de confidentialité de Autopay S.A.">la Politique de confidentialité</a> de Autopay S.A., l\'exécution immédiate du service de paiement et le débit de ma carte de paiement.',
      termsReccuringMore1: 'J\'accepte que le service soit fourni immédiatement et, en cas de résiliation du contrat, je sais que je ne serai pas remboursé des frais engagés pour les services fournis à ma demande jusqu\'au moment de la résiliation du contrat.',
      termsReccuringMore2: 'J\'accepte que ma carte de paiement soit débitée périodiquement par Autopay S.A. dans le but de traiter les transactions pour {receiverName}. Les règles de chargement cyclique de la carte sont définies dans <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Télécharger les conditions générales du service" rel="noreferrer">les Conditions générales</a> du service de paiement. Je déclare avoir connaissance des dates, de la fréquence et des règles de détermination des montants des débits de ma carte de paiement, ainsi que des modalités de retrait de ce consentement, que j\'ai convenu avec {receiverName}. En cas de questions liées au débit cyclique de la carte de paiement, je contacterai {receiverName}.',
      mwfClauseLess: 'Si vous souhaitez réaliser le paiement, acceptez le <a href="{regulationUrl}" target="_blank" title="Règlement du service" rel="noreferrer">Règlement</a> et <a href="{privacyPolicyUrl}" target="_blank" title="la Politique de confidentialité Autopay S.A.</a>, le consentement au commencement de la transaction dans votre banque par l’intermédiaire de Autopay S.A. et de fourniture des détails de cette transaction vers le destinataire et la demande de la réalisation immédiate du service de paiement.',
      mwfClauseMore: 'Je souhaite que le service soit réalisé immédiatement et je sais que je ne recevrai pas de remboursement des frais supportés en cas de résiliation du contrat.',
      mwfClauseARIA: 'Si vous souhaitez effectuer un paiement, acceptez le Règlement et la politique de confidentialité de Autopay S.A., consentez à lancer des transactions auprès de votre banque via Autopay S.A. et fournir les détails de la transaction au bénéficiaire et demander l\'exécution immédiate du service de paiement. Je souhaite que le service soit rendu immédiatement et je sais que je ne serai pas remboursé en cas de rétractation du contrat. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Télécharger le fichier avec les conditions d\'utilisation</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Téléchargez le fichier de la politique de confidentialité de Autopay S.A.</a>',
      paymentInfo: 'La demande de paiement est soumise par l’intermédiaire de Autopay S.A. ayant son siège social à Sopot et sera réalisée conformément aux conditions de paiement déterminées par votre banque. Après avoir sélectionné la banque, vous procèderez à l’autorisation du paiement.',
      changePaywayLink1: 'Vous pouvez encore',
      changePaywayLink2: 'changer le mode de paiement',
      linkMore: 'PLUS',
      linkShowMoreTitle: 'Afficher des informations supplémentaires',
      linkLess: 'MOINS',
      linkShowLessTitle: 'Masquer les informations supplémentaires',
      submitButton: {
        visaMobile: 'Payer par',
        fastTransfer: 'Générez les données pour le versement',
        mwf: 'J’accepte et je paye',
        pis: 'J’accepte et je commence le paiement',
        auto: 'Commander un paiement récurrent',
        autoPayment: 'Paiement de la commande',
        other: 'Payez',
        orange: 'Confirmer',
        c2pNext: 'Suivant',
        c2pAccept: 'J\'accepte',
        c2pSaveAndPay: 'Enregistrer et payer'
      }
    },
    frData: {
      pageTitle: 'Données détaillées pour le transfert',
      header: 'Commandez le virement en utilisant les données indiquées',
      nrbLabel: 'Compte du destinataire',
      nameLabel: 'Nom du destinataire',
      addressLabel: 'Adresse du destinataire',
      titleLabel: 'Libellé du virement',
      amountLabel: 'Montant du virement',
      timeLabel: 'Effectuez la transaction jusqu’au',
      linkCopy: 'Copiez',
      linkGetPdf: 'Téléchargez le PDF contenant les données',
      linkGetPdfTitle: 'Ouvrir dans une nouvelle fenêtre',
      linkGoToBank: 'Connectez-vous à la banque',
      info: 'Connectez-vous à votre compte bancaire. Copiez les données dans le virement. Il est important que les données soient les mêmes, ne changez pas le montant, la devise, le titre, etc.'
    },
    processing: {
      pageTitle: 'Page d\'attente pour le traitement des transactions',
      loaderAnimation: 'Animation affichée pendant l\'attente',
      header: 'Nous sommes en train de préparation de votre site de paiement.',
      description: 'Attendez encore un instant...',
      info: {
        header: 'N’oubliez pas !',
        description: 'Ne fermez pas la page après avoir fini la transaction – si vous le faites, votre argent ne sera pas envoyé vers le destinataire.'
      },
      loaderText: 'Le transfert des données est en cours.<br>Cela nous prendra 30 secondes au maximum.'
    }
  },
  thankYou: {
    pageTitle: 'Informations sur le résultat de la transaction',
    form: {
      emailLabel: 'Courriel',
      buttonSubmit: 'Retournez à la page de notre partenaire'
    },
    negative: {
      header: 'Le paiement n’a pas été effectué à cause de l’absence d’autorisation de la transaction.',
      description: 'Si vous souhaitez toujours profiter de l’offre du vendeur ou du prestataire en question – retournez à son site web, sélectionnez le produit ou le service et effectuez le paiement, tout en utilisant le mode de paiement de votre choix.'
    },
    pending: {
      header: 'Merci d’avoir commandé la paiement par l’intermédiaire de la passerelle de paiement.',
      description: 'La vérification du statut de la transaction est en cours, quand le processus sera fini, vous recevrez une information sur le statut de paiement à votre boîte courriel.'
    },
    positive: {
      header: 'Merci, votre paiement a été effectué.',
      description: 'Nous vous enverrons une confirmation à l’adresse courriel que vous avez indiquée.'
    }
  },
  regulationsApproval: {
    header: 'Accepter la transaction'
  },
  wait: {
    pageTitle: 'Page d\'attente'
  },
  sessionTimeout: {
    pageTitle: 'Page après la fin de la session',
    header: 'Le temps pour compléter le paiement est passé',
    description: 'Vous pouvez retourner au site du partenaire, passer la commande de nouveau ou profiter du service et effectuer le paiement, tout en bénéficiant du mode de paiement de votre choix à tout moment.'
  },
  paywayError: {
    pageTitle: 'Le canal de paiement sélectionné n\'est pas disponible',
    header: 'Malheureusement, le mode de paiement sélectionné est actuellement indisponible.',
    description: 'Veuillez revenir à la page partenaire et réessayer plus tard.'
  },
  loader: {
    defaultAnimation: 'Animation affichée pendant le chargement de la page',
    slider: 'Slider avec textes changeants',
    text1: 'Chez Autopay, nous nous efforçons d’atteindre la neutralité carbone. Vous le faites aussi en profitant de nos services.',
    text2: 'Chez Autopay, nous allons au travail à vélo, tout en réduisant l’émission des gaz à effet de serre à l’atmosphère. Vous le faites aussi en profitant de nos services.',
    text3: 'Chez Autopay, nous sommes alimentés par l’énergie verte. Vous aussi, lorsque vous profitez de nos services.',
    text4: 'Chez Autopay, nous limitons l’usure du papier. Vous le faites aussi, lorsque vous profitez de nos services.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Détails',
    headerAriaLabel: 'Détails de la transaction',
    amountLabel: 'Montant dû',
    receiverLabel: 'Destinataire',
    orderLabel: 'Numéro de commande',
    amountOryginalCurrencyLabel: 'Montant en {currency}',
    currencyRateLabel: 'Taux de change',
    feeLabel: 'Frais de transaction',
    customerNumberLabel: 'Numéro de client',
    invoiceNumberLabel: 'Numéro de facture',
    accountNumberLabel: 'Numéro de compte',
    paywayLabel: 'Mode de paiement',
    paywayName: 'Virement bancaire en ligne',
    linkBack: 'Retour au magasin',
    linkBackTitle: 'Quitter la transaction et retourner au magasin',
    linkMore: 'plus',
    linkLess: 'moins',
    toggleBtnUnfoldAriaLabel: 'Dépliez les détails de la commande',
    toggleBtnFoldAriaLabel: 'Pliez les détails de la commande'
  },
  error: {
    pageTitle: 'Page d\'erreur',
    linkBackUrlSet: 'Retournez au site du partenaire',
    linkBackUrlNotSet: 'Passez au site d\'aide',
    sessionTimeout: {
      title: 'Le temps pour compléter le paiement est passé',
      message: 'Vous pouvez retourner au site du partenaire, passer la commande de nouveau ou profiter du service et effectuer le paiement, tout en bénéficiant du mode de paiement de votre choix à tout moment.'
    },
    notFoundError: {
      pageTitle: 'Erreur page 404 - page introuvable',
      title: 'Il semble que vous êtes allé trop loin et on n’a pas réussi à trouver le site que vous souhaitez visiter.',
      message: 'Raisons possibles : le site n’existe pas ou a été supprimé, des travaux d’entretien sont en cours, l’adresse du site est incorrecte, une erreur technique est survenue.'
    },
    generalError: {
      title: 'Nous sommes désolés',
      message: 'Nous ne pouvons pas réaliser votre virement en ce moment.'
    },
    errFieldNotFound: {
      title: 'Nous sommes désolés',
      message: ''
    },
    errBadClientSource: {
      title: 'Nous sommes désolés',
      message: ''
    },
    nrParametersError: {
      title: 'Nous sommes désolés',
      message: 'Nous ne pouvons pas réaliser votre virement en ce moment.'
    },
    transactionOutdated: {
      title: 'Le temps pour compléter le paiement est passé.',
      message: 'Commandez-le de nouveau.'
    },
    linkValidityTimeOutdated: {
      title: 'Le temps pour compléter le paiement est passé',
      message: 'Commandez-le de nouveau.'
    },
    transactionValidityTimeOutdated: {
      title: 'Le temps pour compléter le paiement est passé.',
      message: 'Commandez-le de nouveau.'
    },
    multiplyTransaction: {
      title: 'Nous sommes désolés',
      message: 'La transaction existe déjà et attend le paiement.'
    },
    transactionCanceled: {
      title: 'Nous sommes désolés',
      message1: 'La transaction a été annulée.',
      message2: 'Commandez-le de nouveau.'
    },
    multiplyPaidTransaction: {
      title: 'Nous sommes désolés',
      message: 'La transaction est déjà payée.'
    },
    transactionReject: {
      title: 'Nous sommes désolés',
      message1: 'Oups, quelque chose s\'est mal passé.',
      message2: 'Nous réparons déjà la passerelle...'
    },
    bankDisabled: {
      title: 'Nous sommes désolés',
      message: 'La banque sélectionnée et inaccessible en ce moment.'
    },
    bankTemporaryMaintenance: {
      title: 'Nous sommes désolés',
      message: 'Nous ne pouvons pas réaliser votre virement en ce moment.'
    },
    insufficientStartAmount: {
      title: 'Nous sommes désolés',
      message: 'La limite du montant du virement a été dépassée.'
    },
    startAmountOutOfRange: {
      title: 'Nous sommes désolés',
      message: 'La limite du montant du virement a été dépassée.'
    },
    nonAccountedLimitExceeded: {
      title: 'Nous sommes désolés',
      message: 'Le paiement ne peut pas être effectué parce que le vendeur a atteint la limite mensuelle des ventes.+.'
    }
  },
  confirmation: {
    pageTitle: 'Informations sur la progression du paiement',
    linkBack: 'Je retourne sur le site',
    linkBackTimeout: 'Retour au site Web du partenaire dans ',
    incorrectAmount: {
      header: 'Montant erroné',
      description: 'Nous n\'avons pas transféré votre paiement au destinataire car le montant était incorrect.',
      description2: 'Cliquez sur le bouton pour revenir à la page et refaites-la. N\'oubliez pas de ne modifier aucune donnée.'
    },
    multiplePaid: {
      header: 'Double dépôt',
      description: 'Le destinataire a reçu votre paiement deux fois. Ne vous inquiétez pas, vous serez remboursé.',
      description2: 'Contactez le destinataire et fournissez-lui une confirmation de paiement.'
    },
    negative: {
      header: 'Fin des temps',
      description: 'Nous n\'avons pas transféré votre paiement au destinataire car le délai de confirmation est expiré.',
      description2: 'Cliquez sur le bouton pour revenir à la page et refaites-la.'
    },
    notFound: {
      header: 'Aucun paiement',
      description: 'Une erreur s\'est produite lors du traitement de votre paiement. Mais rien n\'est perdu.',
      description2: 'Cliquez sur le bouton pour revenir à la page et refaites-la.'
    },
    outdated: {
      header: 'Fin des temps',
      description: 'Nous n\'avons pas transféré votre paiement au destinataire.',
      description2: 'Cliquez sur le bouton pour revenir à la page et refaites-la.'
    },
    outdatedPaidNotRefunded: {
      header: 'Fin des temps',
      description: 'Nous n\'avons pas transféré votre paiement au destinataire. Si de l\'argent a été retiré de votre compte, ne vous inquiétez pas, vous recevrez votre remboursement dans quelques jours.',
      description2: 'Cliquez sur le bouton pour revenir à la page et refaites-la.'
    },
    outdatedPaidRefunded: {
      header: 'Fin des temps',
      description: 'Nous n\'avons pas transféré votre paiement au destinataire. Si l\'argent a été retiré de votre compte, ne vous inquiétez pas, nous l\'avons déjà remboursé.',
      description2: 'Cliquez sur le bouton pour revenir à la page et refaites-la.'
    },
    pending: {
      header: 'En cours',
      description: 'Nous traitons votre paiement. Nous vous informerons lorsqu\'il parviendra au destinataire.'
    },
    positive: {
      header: 'Payé',
      visaVideo: 'Vidéo publicitaire diffusée après une transaction réussie',
      description: 'Nous avons transféré vos fonds au destinataire.'
    }
  },
  messages: {
    accessForbidden: 'Pas d’accès',
    internalServerError: 'Une erreur inattendue est survenue. Réessayez plus tard.',
    validators: {
      required: 'Champ requis',
      pattern: 'Veuillez fournir une valeur valide',
      emailRequired: 'Adresse courriel est requise.',
      emailNotValid: 'Votre adresse courriel contient une erreur. Saisissez votre adresse courriel correcte',
      emailNotRecognized: 'L\'adresse email fournie n\'est pas enregistrée',
      emailIdentityLookup: 'Échec de la vérification de l\'adresse e-mail',
      emailLocked: 'L\'adresse email fournie est temporairement bloquée dans le système Mastercard',
      paywayRequired: 'Sélectionnez le mode de paiement',
      creditCardRequired: 'Remplissez tous les champs',
      phoneRequired: 'S\'il vous plaît entrer un numéro de téléphone valide',
      regulationRequired: 'Acceptez le règlement',
      requiredConsent: 'Consentement requis',
      codeNotValid: 'Le code fourni n\'est pas valide',
      codeAccountLocked: 'Le compte a été temporairement bloqué dans le système Mastercard'
    },
    validatorsAria: {
      required: 'Attention. Le champ du formulaire n\'a pas été rempli. Champs requis.',
      pattern: 'Attention. Le champ contient une valeur non valide.',
      emailRequired: 'Attention. Le champ email n\'a pas été rempli. S\'il vous plaît, mettez une adresse email valide.',
      emailNotValid: 'Attention. Votre adresse e-mail contient une erreur. S\'il vous plaît, mettez une adresse email valide.',
      emailNotRecognized: 'Attention. L\'adresse e-mail fournie n\'est pas enregistrée.',
      emailIdentityLookup: 'Attention. L\'adresse e-mail fournie n\'a pas pu être vérifiée',
      emailLocked: 'Attention. L\'adresse e-mail fournie est temporairement bloquée dans le système Mastercard.',
      paywayRequired: 'Attention. Le paiement n\'a pas été sélectionné. Choisissez le mode de paiement.',
      creditCardRequired: 'Attention. Remplissez tous les champs avec les détails de la carte de crédit.',
      phoneRequired: 'Attention. Le champ du numéro de téléphone contient une erreur. S\'il vous plaît entrer un numéro de téléphone valide.',
      codeNotValid: 'Attention. Le code fourni n\'est pas valide.',
      codeAccountLocked: 'Attention. Le compte a été temporairement bloqué dans le système Mastercard.'
    },
    success: {
      copy: 'Copié!',
      codeSent: 'Le code a été envoyé!'
    },
    errors: {
      getPdf: 'On n’a pas réussi à télécharger le fichier PDF!',
      copy: 'On n’a pas réussi à copier!',
      thankYouUpdate: 'On n’a pas réussi à enregistrer l’adresse courriel !',
      groupInactive: 'Pour le moment, il est impossible d\'effectuer un transfert en utilisant le groupe {groupName}. Veuillez sélectionner un autre mode de paiement.',
      paywayInactive: 'En ce moment, pour des raisons techniques, il est impossible d’effectuer le virement depuis la banque sélectionnée. Sélectionnez une autre banque ou un autre mode de paiement.',
      googlePayInactive: 'Malheureusement, le paiement Google Pay n’est pas disponible sur votre appareil.',
      applePayInactive: 'Le paiement Apple Pay est disponible pour les personnes utilisant le navigateur web Safari et les appareils avec le système d’exploitation iOS.',
      codeNotSent: 'Échec du renvoi du code !',
      codeCounterExceeded: 'Le nombre de tentatives autorisées a été dépassé. Saisissez manuellement les détails de votre carte ou utilisez un autre mode de paiement.'
    }
  },
  modal: {
    closeButton: 'Fermer',
    confirmButton: 'Je comprends'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Paiement récurrent',
        description: 'Entrez les détails de la carte que vous utiliserez pour le paiement. Les paiements automatiques seront prélevés sur votre carte ultérieurement.',
        labelInitWithRefund: 'Paiement de vérification',
        labelInitWithRefundOrange: 'Ajouter une carte de paiement',
        labelInitWithPayment: 'Paiement par carte',
        descriptionInitWithRefund: 'Entrez les détails de la carte que vous souhaitez vérifier pour les paiements futurs. À l\'étape suivante, nous facturerons des 1 PLN à partir de la carte donnée pour vérifier l\'exactitude des données. Après vérification, nous vous rembourserons le montant.',
        descriptionInitWithPayment: 'Entrez les détails de la carte que vous utiliserez pour effectuer la transaction et vérifiez-la en même temps pour les paiements futurs.'
      },
      blik: {
        label: 'BLIK',
        description: 'Payez immédiatement en saisissant le code BLIK, sans connexion ni inscription'
      },
      card: {
        label: 'Paiement par carte',
        description: 'Payez avec votre carte de paiement'
      },
      pbl: {
        label: 'Virement sur internet',
        description: 'Sélectionnez la banque à partir de laquelle vous souhaitez effectuer un paiement',
        descriptionVerify: 'Vérification d\'identité en ligne',
        descriptionServiceVerify: 'Sélectionnez la banque qui vous permettra de vérifier le service en ligne'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Paiement mobile Visa'
      },
      wallet: {
        label: 'Portefeuille virtuel',
        description: 'Payez sans nécessité de connexion aux services bancaires sur Internet'
      },
      installments: {
        label: 'Répartissez en plusieurs versements',
        description: 'Payez vos achats en utilisant des versements pratiques'
      },
      alior: {
        descriptionInfo1: 'Payez vos achats en plusieurs versements pratiques',
        descriptionInfo2: '(vérifier les détails)',
        aliorModal: {
          text1: 'En fonction des paramètres du site Internet sur lequel vous effectuez l\'achat, vous pouvez acheter des produits en plusieurs fois dans les variantes suivantes :',
          text2: '<ul><li>10 versements 0%</li><li>20 versements 0%</li><li>de 3 à 48 versements avec un coût mensuel de 1% (selon l\'exemple représentatif donné pour une transaction donnée)</li></ul>'
        }
      },
      otp: {
        label: 'Payez plus tard',
        description: 'Achetez maintenant, payez plus tard',
        descriptionInfo1Mwf: 'Payez plus tard pour la facture - jusqu\'à 45 jours à la fois ou en plusieurs versements égaux',
        descriptionInfo1Ecommerce: 'Payez plus tard - jusqu\'à 45 jours à la fois ou en plusieurs versements égaux',
        descriptionInfo2Mwf: 'Informations sur l\'intermédiaire de crédit et le coût',
        descriptionInfo2Ecommerce: 'Informations sur les coûts',
        labelPayka: 'Payka',
        descriptionPayka: 'Payez plus tard pour la facture - un paiement unique différé jusqu\'à 30 jours ou 4 versements égaux <a href="" id="paykaShowInModal">Informations sur l\'intermédiaire de crédit et le coût</a>',
        descriptionBlikPayLater: 'Le service est disponible chez Millenium Bank et VeloBank',
        paykaModalMwf: {
          header1: 'Informations sur l\'intermédiaire de crédit ',
          text1: 'Autopay SA dont le siège se situe à Sopot agit en tant qu\'intermédiaire de crédit pour Paytree SA dont le siège se situe à Gdańsk (le Prêteur), autorisé à présenter l\'offre de crédit aux clients et à les rediriger vers le site Web du Prêteur, y compris la demande de crédit. Détails de l\'offre sur :  <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Données de coût',
          text2: 'Payez plus tard – jusqu\'à 45 jours à la fois ou en plusieurs versements égaux. Données de coût pour un exemple représentatif: Remboursement dans les 45 jours TAEG 0%; Remboursement échelonné TAEG 86,83% Détails de l\'offre sur: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Données de coût',
          text2: 'Payez plus tard – jusqu\'à 45 jours à la fois ou en plusieurs versements égaux. Données de coût pour un exemple représentatif: Remboursement dans les 45 jours TAEG 0%; Remboursement échelonné TAEG 86,83% Détails de l\'offre sur: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Achetez maintenant, payez dans 30 jours',
          header2: 'Code BLIK',
          text2: 'Vous entrez le code BLIK et nous vous offrons une limite d\'achat allant jusqu\'à 4 000 PLN',
          header3: 'Vous achetez des produits et les payez plus tard',
          text3: 'Vous avez le temps de vérifier si tout vous convient',
          header4: 'Vous payez sous 30 jours sans frais',
          text4: 'Vous pouvez également retourner vos achats dans le délai précisé par le magasin'
        }
      },
      companyPayments: {
        label: 'Paiements de l\'entreprise',
        description: 'Décaler la date de paiement des achats de l\'entreprise de 30 jours'
      },
      transfer: {
        label: 'Données pour le virement',
        description: 'Commandez le virement en utilisant les données indiquées'
      },
      other: {
        label: 'Autres',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Nous sommes désolés. Actuellement, nous ne pouvons pas réaliser votre virement. La banque sélectionnée n\'est pas disponible en ce moment..',
        blockMultipleTransactions: 'Nous sommes désolés. La transaction existe déjà et attend le paiement.',
        blockPaidTransactions: 'Nous sommes désolés. La transaction a été déjà payée. Vous ne pouvez pas l’effectuer de nouveau.',
        canceledTransactions: 'La transaction que vous essayez de payer a été annulée et est indisponible. Veuillez commencer une nouvelle transaction.',
        declined: 'Refus de l’autorisation.',
        generalError: 'Un problème temporaire avec la connexion est survenu. Nous ne pouvons pas réaliser votre paiement en ce moment. Réessayez plus tard.',
        insufficientStartAmount: 'Nous sommes désolés. La limite du montant du virement a été dépassée.',
        internalServerError: 'Oupsss.. on n’a pas réussi :(. Nous travaillons sur la solution du problème. Réessayez plus tard.',
        nonAccountedLimitExceeded: 'Le paiement ne peut pas être réalisé, parce que le vendeur a atteint la limite mensuelle des ventes.',
        outdatedError: 'Le temps pour compléter le paiement a expiré..',
        paid: 'La transaction a été terminée avec succès.',
        paywayLoaded: 'On a chargé le site de virement rapide',
        paywaylistLoaded: 'On a chargé le site de sélection des modes de paiement'
      }
    }
  },
  maintenance: {
    text1: 'Nous sommes en cours du nettoyage du Nouvel An dans la passerelle de paiement de Autopay, par conséquent, il est impossible de commander un paiement en ce moment. Nous sommes désolés pour les inconvénients..',
    text2: 'La passerelle fonctionnera de nouveau déjà à compter du 16 janvier à 10h00.',
    text3: 'Merci de votre patience.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Entrez les détails du propriétaire du compte',
      flowLabel: 'Propriétaire du compte',
      placeholder: 'Entrez les détails du propriétaire du compte'
    },
    nip: {
      label: 'Entrez votre numéro d\'identification fiscale',
      flowLabel: 'NIP',
      placeholder: 'Entrez votre numéro d\'identification fiscale'
    }
  }
}
